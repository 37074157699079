import { navigate } from 'gatsby'
import { useEffect } from 'react'

export default () => {
  useEffect(() => {
    navigate(
      'https://decathlonstore.vteximg.com.br/arquivos/regulamento-clube-decathlon.pdf'
    )
  }, [])
}
